import { defineStore } from "pinia";
import { BigNumber, Contract, ethers, providers } from "ethers";
import { DEFAULT_CHAINID, chainIds } from "../config";
import { NETWORKS } from "./utils/chains";
import contractJson from "../contracts/contracts.json";
import { ChainId, Metamask } from "./utils/wallets/metamask";
import { Fund, PAC } from "../contracts/typechain";
import { Connector } from "wagmi";
// import {TKN} from "@/contracts/typechain/TKN"

function findContract(key: string): any {
    const contracts = contractJson[DEFAULT_CHAINID][0].contracts;
    const value = contracts[key as keyof typeof contracts];

    if (value) return value;

    throw `${key} not found in ${NETWORKS[DEFAULT_CHAINID].short} into contracts.json`;
}

function getABI(key: string): any {
    return findContract(key).abi;
}

function getAddress(key: string): string {
    return findContract(key).address;
}

function getProvider(chainId: ChainId): providers.JsonRpcProvider {
    return new providers.JsonRpcProvider(NETWORKS[chainId].rpc);
}
function getContracts() {
    const jsonProvider = getProvider(DEFAULT_CHAINID);
    const fundAddress = getAddress("Fund");
    const fundAbi = getABI("Fund");
    const fund = new Contract(fundAddress, fundAbi, jsonProvider) as Fund;

    return {
        fund,
    };
}

export interface Transactions {
    timestamp: BigNumber;
    amount: BigNumber;
    sellTikets: boolean;
    buyTickets: boolean;
}

export class useFundStore {
    provider: Metamask | undefined
    constructor() {
        this.provider = new Metamask(chainIds, chainIds[0])
    }


    async getWallets(): Promise<([string, BigNumber, string[]] & { addr: string; ticketsBought: BigNumber; participants: string[]; })[]> {
        return new Promise(async (resolve, reject) => {
            const { fund } = getContracts();
            const res = []
            const maxTicketInPool = await this.maxTicketsAmountInPool()
            const a = await fund.getWallets()
            let lastFullPull = -1
            // логика на открытие пулла при заполнении старого
            for (let index = 0; index < a.length; index++) {
                if (Number(a[index].ticketsBought) != 0) {
                    if (Number(a[index].ticketsBought) == maxTicketInPool) {
                        lastFullPull = index
                    }
                    res.push(a[index])
                }
                else {
                    if (index == lastFullPull + 1) {
                        res.push(a[index])
                    }
                }
            }
            resolve(res);
        })
    }
    async getAllLeftTickets(): Promise<number> {
        return new Promise(async (resolve, reject) => {
            const { fund } = getContracts();
            const wallets = await this.getWallets();
            let allTicketsBought = 0;
            const maxTicketsInPool = await this.maxTicketsAmountInPool()
            wallets.map(wallet => {
                allTicketsBought += maxTicketsInPool - wallet.ticketsBought.toNumber();
            })
            resolve(allTicketsBought)
        })
    }
    async getTotalTickets(): Promise<number> {
        return new Promise(async (resolve, reject) => {
            const { fund } = getContracts();
            const wallets = await this.getWallets();
            let totalTickets = 0;
            //hardcode :(
            const maxTicketsInPool = await this.maxTicketsAmountInPool()
            console.log(maxTicketsInPool);

            wallets.map(wallet => {

                totalTickets += maxTicketsInPool;
            })

            resolve(totalTickets)
        })
    }
    async getLeftTickets(id: BigNumber): Promise<bigint> {
        const { fund } = getContracts();
        const wallets = await fund.getWallets();
        return 100n - wallets[id.toNumber()].ticketsBought.toBigInt();
    }
    async getAllUserTickets(): Promise<number> {
        return new Promise(async (resolve, reject) => {
            await this.provider!.connect()
            const { fund } = getContracts();
            const wallets = await fund.getWallets()
            let bigAmount = 0
            for (let index = 0; index < wallets.length; index++) {
                bigAmount += (await fund.userStats(index, this.provider!.address)).ownedTicketsAmount.toNumber();
            }

            resolve(bigAmount)
        })
    }
    async getLenOnSell(id: BigNumber): Promise<number> {
        const { fund } = getContracts();
        let res = await fund.getLenOnSell(id)
        return Number(res);
    }
    async getLenOnBuy(id: BigNumber): Promise<number> {
        const { fund } = getContracts();
        let res = await fund.getLenOnBuy(id);
        return Number(res)
    }
    async getUserAmount(id: number): Promise<number> {
        await this.provider!.connect()
        const { fund } = getContracts();
        let bigAmount = 0n;
        bigAmount += (await fund.userStats(id, this.provider!.address)).ownedTicketsAmount.toBigInt();
        return (Number(bigAmount))
    }
    async getUser(address: string, id: BigNumber): Promise<[BigNumber, BigNumber, BigNumber] & { ownedTicketsAmount: BigNumber; onSell: BigNumber; onBuy: BigNumber }> {
        const { fund } = getContracts();
        let res = await fund.userStats(id, address)
        console.log(res);

        return res
    }
    async maxTicketsAmountInPool(): Promise<number> {
        return new Promise(async (resolve, reject) => {
            resolve(100)
        })
    }

    async buyTickets(id: BigNumber): Promise<boolean> {
        await this.provider!.connect()

        const { fund } = getContracts();

        const TicketPrice = await fund.TicketPrice();
        console.log(Number(TicketPrice));

        const tx = await (
            await fund.connect(this.provider!.signer).buyTickets(id, { value: TicketPrice })
        ).wait();

        return tx != null;
    }
    async sellTickets(id: BigNumber): Promise<boolean> {
        await this.provider!.connect()

        const { fund } = getContracts();
        const tx = await (
            await fund.connect(this.provider!.signer).sellTickets(id)
        ).wait();
        return tx != null;
    }

    async fundCancelBuy(id: BigNumber, amount: BigNumber): Promise<boolean> {
        await this.provider!.connect()

        const { fund } = getContracts();
        const tx = await (
            await fund.connect(this.provider!.signer).cancelBuy(id, amount)
        ).wait();
        return tx != null;
    }
    async fundCancelSell(id: BigNumber, amount: BigNumber): Promise<boolean> {
        await this.provider!.connect()

        const { fund } = getContracts();
        const tx = await (
            await fund.connect(this.provider!.signer).cancelSell(id, amount)
        ).wait();
        return tx != null;
    }

}