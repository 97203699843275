import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { Metamask } from "../../scripts/utils/wallets/metamask"
import { useFundStore } from "../../scripts/Fund"

export type PoolsType = {
    index: number
    user_tickets: number,
    available_tickets: number,
    sales_requests: number,
    purchase_requests: number,
    user_on_sell: number | null,
    user_on_buy: number | null,
}


type IInitialState = {
    user_address: string,
    all_user_tickets: number | null,

    total_tickets: number | null,
    pools: PoolsType[] | undefined,
    poolStatus: 'Uninitialized' | 'pending' | 'fulfilled',
    available_tickets: number | null,
    connector: Metamask | undefined
    maxTicketInPool: number | null
}


const initialState: IInitialState = {
    user_address: '',
    maxTicketInPool: null,
    all_user_tickets: null,
    total_tickets: null,
    pools: [],
    poolStatus: 'Uninitialized',
    available_tickets: null,
    connector: undefined,

}

export const RedeSlice = createSlice({
    name: "RedeSlice",
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setAddress: (state, action: PayloadAction<string>) => {
            state.user_address = action.payload
        },
        setTotalTickets: (state, action: PayloadAction<number>) => {
            state.total_tickets = action.payload
        },
        setMaxTicketInPool: (state, action: PayloadAction<number>) => {
            state.maxTicketInPool = action.payload
        },
        setPools: (state, action: PayloadAction<PoolsType>) => {
            state?.pools?.push(action.payload)
        },
        clearPools: (state) => {
            state.pools = []
        },
        setPullStatus: (state, action: PayloadAction<'Uninitialized' | 'pending' | 'fulfilled'>) => {
            state.poolStatus = action.payload
        },
        setAvailableTickets: (state, action: PayloadAction<number>) => {
            state.available_tickets = action.payload
        },
        setAllUserTickets: (state, action: PayloadAction<number>) => {
            state.all_user_tickets = action.payload
        },
        setConnector: (state, action: PayloadAction<any>) => {
            state.connector = action.payload
        }

    },
})

export const { clearPools, setMaxTicketInPool, setConnector, setPullStatus, setAddress, setTotalTickets, setPools, setAvailableTickets, setAllUserTickets } = RedeSlice.actions